import { Box, Grid, Paper, Typography, Button } from "@mui/material"
import { styled } from "@mui/material";
import { useEffect, useState } from 'react';
import one from '../../images/1.png';
import two from '../../images/2.png';
import three from '../../images/3.png';

const StyledPaper = styled(Paper)({
  padding: "24px",
  height: "100%",
  transition: "transform 0.2s ease-in-out",
  cursor: "pointer",
  "&:hover": {
    transform: "translateY(-4px)",
  },
})


export default function Insights() {
    const [selected, setSelected] = useState(0);
    const images = [one, two, three];

    useEffect(() => {
        const interval = setInterval(() => {
            setSelected(prev => (prev + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 4, mb: 20 }}>
            <Typography
                variant="h2"
                component="h1"
                sx={{
                    textAlign: "center",
                    mb: 6,
                    fontWeight: "bold",
                    color: "#1a1a1a",
                }}
            >
                Our Insights
            </Typography>

            <Grid container spacing={4}>
                {/* Left Column - Articles */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                        <StyledPaper elevation={2} sx={{ backgroundColor: selected === 0 ? "#bdb2e2" : "#fff" }}>
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "500", mb: 1 , fontSize: '18pt'}}
                            >
                                Key Financial Aspects for Indian Startups
                            </Typography>
                        </StyledPaper>

                        <StyledPaper elevation={2} sx={{ backgroundColor: selected === 1 ? "#bdb2e2" : "#fff" }}>
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "500", mb: 1 , fontSize: '18pt'}}
                            >
                                Talent Acquisition for Startups varying from Larger Companies
                            </Typography>
                        </StyledPaper>

                        <StyledPaper elevation={2} sx={{ backgroundColor: selected === 2 ? "#bdb2e2" : "#fff" }}>
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "500", mb: 1 , fontSize: '18pt'}}
                            >
                                GenAI Impact on various Industries and a Brief Outlook
                            </Typography>
                        </StyledPaper>
                    </Box>
                </Grid>

                {/* Right Column - Image */}
                <Grid item xs={12} md={6}>
                    <Paper
                        elevation={0}
                        sx={{
                            height: "100%",
                            borderRadius: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '20px'
                        }}
                    >
                        <Box
                            component="img"
                            src={images[selected]}
                            alt="Business meeting with people collaborating"
                            sx={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "white",
                                color: "#1a1a1a",
                                "&:hover": {
                                    backgroundColor: "#f5f5f5",
                                },
                                boxShadow: 0,
                            }}
                        >
                            Know More
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
